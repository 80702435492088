export const enum ROLE_TYPE {
  ADMIN = '0',
  USER = '1',
}

export const enum PAGE_TYPE {
  ALL_PREV,
  PREV,
  NEXT,
  ALL_NEXT,
}

export const enum YN_TYPE {
  Y = 'Y',
  N = 'N',
}

export const enum LOG_TABLE_TYPE {
  LOG,
  LOCKER,
  USER
}

export const enum LOCKER_STATE {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  CONNECT = 'CONNECT',
}

export const enum LOCKER_TYPE {
  LOCKER = 'LOCKER',
  DOOR = 'DOOR',
  BOOKSHELF = 'BOOKSHELF'
}

export const enum LOG_TAB_TYPE {
  LOCK_LOG = 0,
  CHANGE_LOG = 1,
}

export const enum LOCKER_MODE {
  SERVER = 1,
  STAND_ALONE = 2,
}

import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { ROLE_TYPE } from 'types/enumType';
import { SidebarItemType } from 'types/sidebar';
import { decryptString } from 'util/encryptUtil';

import SidebarNavItem from './SidebarNavItem';

type SidebarNavListProps = {
  items: SidebarItemType[];
};

export default function SidebarNavList({ items }: SidebarNavListProps) {
  const userInfo = useRecoilValue(userInfoState);
  return (
    <ul>
      {items &&
        items.map((item) => {
          if (
            (item.href === '/inquiry' ||
              item.href === '/locker' ||
              item.href === '/user') &&
            decryptString(userInfo.role) === ROLE_TYPE.USER
          ) {
            return null;
          }
          return (
            <SidebarNavItem
              key={item.href}
              item={item}
              className={'depth-01'}
            />
          );
        })}
    </ul>
  );
}

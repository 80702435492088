import { SidebarItemType } from 'types/sidebar';

const dashboardItemList: SidebarItemType[] = [
  {
    href: '/log',
    title: '이력 관리',
    name: '이력 관리',
  },
  {
    href: '/locker',
    title: 'Locker 관리',
    name: 'Locker 관리',
  },
  {
    href: '/user',
    title: '사용자 관리',
    name: '사용자 관리',
  },
  {
    href: '/inquiry',
    title: '고객 문의',
    name: '고객 문의',
  },
];

export default dashboardItemList;

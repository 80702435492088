import { useNavigate } from 'react-router-dom';

import { signOut } from 'api/sessionApi';
import { Button } from 'components/widget/Button';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { decryptString } from 'util/encryptUtil';
import { convertRoleToString } from 'util/stringUtil';

export default function Header() {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoState);
  const resetUserInfo = useResetRecoilState(userInfoState);

  const handleChangeInfo = () => {
    navigate('/mypage');
  };

  const handleSignOut = async () => {
    await signOut();
    resetUserInfo();
    sessionStorage.clear();
  };

  return (
    <header className={'header'}>
      <h1 className={'logo-title'}>
        <i className={'logo'} />
        <span>{'LOCKER Manage 시스템'}</span>
      </h1>
      <section className={'header-right-section'}>
        <div className={'header-info'}>
          <label
            htmlFor={'user-name'}
          >{`${convertRoleToString(decryptString(userInfo.role))}: `}</label>
          <span className={'user-name'} id={'user-name'}>
            {userInfo.name}
          </span>
          <button
            type={'button'}
            className={'p-right btn-type-01'}
            onClick={handleChangeInfo}
          >
            {'정보변경'}
          </button>
        </div>
        <div className={'header-info'}>
          <label htmlFor={'last-login'}>{'최근로그인 :'}</label>
          <time id={'last-login'}>
            <span>{userInfo.latestDt}</span>
          </time>
          <Button
            name={'로그아웃'}
            className={'p-right btn-type-02'}
            onClick={handleSignOut}
          />
        </div>
      </section>
    </header>
  );
}

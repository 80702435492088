import SignInComponent from '../components/SignIn';

export default function SignIn() {
  return (
    <section className={'login-section'}>
      <h1 className={'logo-title'}>
        <i className={'logo'} />
        <span>{'Locker'}</span>
      </h1>
      <SignInComponent />
    </section>
  );
}

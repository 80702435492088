/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { lazy } from "react"


export const retryLazy = (componentImport: any) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(
      window.sessionStorage.getItem('pageRefreshed') || 'false'
    )
    try {
      const component = await componentImport()
      window.sessionStorage.setItem('pageRefreshed', 'false')
      return component
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        window.sessionStorage.setItem('pageRefreshed', 'true')
        return window.location.reload()
      }
      throw error
    }
  })

// 추후 아래 사이트 참조하여 적용 필요
// https://www.codemzy.com/blog/fix-chunkloaderror-react
// export const lazyRetry = function (componentImport: any) {
//   return new Promise((resolve, reject) => {
//     const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');


//     componentImport().then((component: unknown) => {
//       window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
//       resolve(component);
//     }).catch((error: any) => {
//       if (!hasRefreshed) {
//         window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
//         return window.location.reload();
//       }
//       reject(error);
//     })
//   })
// }


import crypto from 'crypto-js';

import { ROLE_TYPE } from 'types/enumType';

const SECRET_KEY = `${process.env.REACT_APP_BASE_URL}`;
export const encryptString = (message: string | number): string => crypto.AES.encrypt(message.toString(), SECRET_KEY).toString();
export const decryptString = (message: string): string => {
  try {
    const role = crypto.AES.decrypt(message, SECRET_KEY).toString(crypto.enc.Utf8)

    if (!role || !(role === ROLE_TYPE.ADMIN || role === ROLE_TYPE.USER)) {
      return '';
    }


    return role;
  } catch (error) {
    console.log("[Error]", error);
    return '';
  }
}
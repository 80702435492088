import React from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarItemType } from 'types/sidebar';

type SidebarNavItemProps = {
  item: SidebarItemType;
  className: string;
};

export default function SidebarNavItem({
  item,
  className,
}: SidebarNavItemProps) {
  return (
    <li className={className} key={item.href}>
      <NavLink to={item.href}>{item.name}</NavLink>
    </li>
  );
}

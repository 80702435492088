import { createBrowserRouter } from 'react-router-dom';

import Default from 'pages/Default';
import SignIn from 'pages/SignIn';
import { retryLazy } from 'util/lazyUtil';

import AuthGuard from './AuthGuard';
import ProtectedRoute from './ProtectedRoute';

const Mypage = retryLazy(() => import('pages/Mypage'));
const LogList = retryLazy(() => import('pages/LogList'));
const LockerList = retryLazy(() => import('pages/LockerList'));
const LockerDetail = retryLazy(() => import('pages/LockerDetail'));
const UserList = retryLazy(() => import('pages/UserList'));
const UserDetail = retryLazy(() => import('pages/UserDetail'));
const AuthConfirmPassword = retryLazy(
  () => import('pages/AuthConfirmPassword'),
);
const InquiryList = retryLazy(() => import('pages/InquiryList'));

const routes = createBrowserRouter([
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Default />
      </AuthGuard>
    ),
    children: [
      { path: '/auth-confirm', element: <AuthConfirmPassword /> },
      {
        path: '/mypage',
        element: (
          <ProtectedRoute>
            <Mypage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/log',
        element: <LogList />,
      },
      {
        path: '/locker',
        element: <LockerList />,
      },
      {
        path: '/locker/:lockerId',
        element: <LockerDetail />,
      },
      {
        path: '/user',
        element: <UserList />,
      },
      {
        path: '/user/:userId',
        element: <UserDetail />,
      },
      {
        path: '/Inquiry',
        element: <InquiryList />,
      },
    ],
  },
]);

export default routes;

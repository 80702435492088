import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Account, useSignInMutation } from 'api/sessionApi';
import { useRecoilState } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { encryptString } from 'util/encryptUtil';

export default function SignIn() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Account>();
  const { mutate } = useSignInMutation();

  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const onSubmit: SubmitHandler<Account> = (data) => {
    mutate(data, {
      onSuccess(response) {
        if (response.code === 200) {
          const { name, latestDt, role } = response.info;
          setUserInfo({
            role: encryptString(role),
            name,
            latestDt,
            isAuthenticated: true,
          });
          navigate('/log');
        } else {
          alert(response.message);
        }
      },
    });
  };

  return (
    <div className={'login-box-wrap'}>
      <h2 className={'p-center'}>{'LOGIN'}</h2>
      <p className={'label-text-01 p-center'}>
        {'enter your details to sign in to your account'}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'input-box2 mt-20'}>
          <input
            type={'text'}
            {...register('account', { required: '아이디를 입력해주세요' })}
          />
          <span>{'Username'}</span>
          <i />
        </div>
        <div className={'input-box2'}>
          <input
            type={'password'}
            {...register('passwd', { required: '비밀번호를 입력해주세요' })}
          />
          <span>{'Password'}</span>
          <i />
        </div>
        <button type={'submit'} className={'btn-type-login'}>
          {'Login'}
        </button>
      </form>
    </div>
  );
}

import { LOCKER_MODE, ROLE_TYPE } from "types/enumType";

export const convertRoleToString = (role: ROLE_TYPE | string): string => {
  switch (role) {
    case ROLE_TYPE.ADMIN:
      return '슈퍼 관리자';
    case ROLE_TYPE.USER:
      return '사용자';
    default:
      return '사용자';
  }
}

export const convertModeToName = (mode: LOCKER_MODE): string => {
  switch (mode) {
    case LOCKER_MODE.SERVER:
      return '서버모드';
    case LOCKER_MODE.STAND_ALONE:
      return '독립모드';
    default:
      return '-';
  }
}
import { useMutation } from '@tanstack/react-query';

import { User } from 'model/User';

import { BaseResp } from './BaseResp';
import { client } from './client';

export type Account = {
  account: string;
  passwd: string;
};

interface SignInResp extends BaseResp {
  info: User;
}

const signIn = (account: Account): Promise<SignInResp> => client.post('/v1/account/login', { ...account });
export const useSignInMutation = () => useMutation({ mutationFn: signIn });

export const signOut = () => client.delete('/v1/account/logout');

const checkPassword = (passwd: string): Promise<BaseResp> => client.post('/v1/account/check-pass', { passwd });
export const useCheckPasswordMutation = () => useMutation({ mutationFn: checkPassword });



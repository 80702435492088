import React from 'react';
import SidebarNavList from './SidebarNavList';
import dashboardItemList from './dashboardItemList';

export default function Sidebar() {
  return (
    <nav className={'left-menu'} data-simplebar>
      <SidebarNavList items={dashboardItemList} />
    </nav>
  );
}
